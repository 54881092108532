<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-membership-card-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">اضافه کردن اشتراک</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <ErrorBox :errors="errors" class="mb-5" />
      <form @submit.prevent="submit">
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-3">
          <MnrText
            v-model="inputs.name"
            title="نام این اشتراک"
            placeholder="یک نام وارد کنید"
          />
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-3 mt-3">
          <MnrNumber v-model="inputs.price" title="قیمت" before="تومان" />
          <MnrNumber v-model="inputs.days" title="تعداد روز" before="روز" />
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-3 mt-5">
          <MnrTextarea
            v-model="inputs.info"
            class="col-span-2"
            title="تویضیحات این اشتراک"
          />
        </div>
        <div class="sm:w-60 w-full mt-3 mx-auto">
          <Button type="submit" class="mt-5 text-sm h-11 lg:text-lg" color="sky">
            ثبت
          </Button>
        </div>
      </form>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import Button from "@/components/Button.vue";
import MnrTextarea from "@/components/mnr/MnrTextarea.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import _ from "lodash";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    BodySimple,
    Icon,
    MnrText,
    Button,
    MnrTextarea,
    MnrNumber,
    ErrorBox,
  },
  data() {
    return {
      firstLoading: true,
      channel: {},
      errors: [],
      L_: _,
      inputs: {
        name: "",
        price: 10000,
        days: 30,
        info: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.$axios
        .get(`/api/owner-channels/${$this.$route.params.channelId}`, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    submit() {
      let $this = this;
      $this.errors = [];
      $this.firstLoading = true;
      $this.$axios
        .post(`/api/owner-channels/${$this.$route.params.channelId}/account`, {
          id: $this.$store.state.user.id,
          inputs: $this.inputs,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push({
            name: "ChannelOwnerSettingVip",
            params: {
              id: $this.$store.state.user.id,
              channelId: $this.$route.params.channelId,
            },
          });
        })
        .catch(function (error) {
          // console.log(error);
          $this.errors = error.data;
        })
        .then(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>

<style></style>
